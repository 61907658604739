import React from 'react'

import LinkButton from '@lib/common/buttons/link-button'

import { Content, Logo, NotFound as NotFoundLogo, Title } from './styles'

const NotFound = () => (
  <Content>
    <Logo />
    <NotFoundLogo />
    <Title>Oops! The page you were looking for doesn't exist.</Title>
    <LinkButton href='/' variant='primary'>
      Home
    </LinkButton>
  </Content>
)

export default React.memo(NotFound)
