import styled from 'styled-components'
import { Flex } from '@fullfacing/schoolbus'

// TODO: When we have moved the layour component out of _app.js
//       to every page, then the position fixed can be removed
export const Content = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
})`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grayscale.dark};
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Logo = styled.div`
  background-image: url('https://cdn.fullfacing.io/images/77761549-5e4d-4242-90f2-db61c167332c.svg');
  height: 50px;
  width: 340px;
  max-width: 340px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export const NotFound = styled.div`
  background-image: url('https://cdn.fullfacing.io/images/dce5471b-ceca-4c5a-add8-39936fb8ebe8.svg');
  height: 250px;
  width: 340px;
  max-width: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 2vw 1em;
`

export const Title = styled.h1`
  font-size: 1.6em;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  max-width: 40em;
  margin: 0 1em 2em;
  font-weight: ${({ theme }) => theme.fontWeights.light};
`
